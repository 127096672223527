<template>
	<div v-if="clinic" class="row">
		<!--begin::Navbar-->
		<div class="card mb-5 mb-xl-10">
			<div class="card-body pt-8 pb-3">
				<!--begin::Details-->
				<div
					class="d-flex flex-row mb-3 justify-content-between align-items-center"
				>
					<div class="d-flex flex-row align-items-center">
						<!--begin: Pic-->
						<div class="me-5 mb-4">
							<div class="badge badge-light-danger p-5">
								<i class="bi bi-building fs-2x text-danger"></i>
							</div>
						</div>
						<!--end::Pic-->

						<!--begin::Info-->
						<div class="flex-grow-1">
							<!--begin::Title-->
							<div
								class="d-flex justify-content-between align-items-start flex-wrap mb-1"
							>
								<!--begin::User-->
								<div class="d-flex flex-column">
									<!--begin::Name-->
									<div class="d-flex align-items-center mb-2">
										<a
											href="#"
											class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
											>{{ clinic.name }}</a
										>
										<a href="#">
											<span class="svg-icon svg-icon-1 svg-icon-primary">
												<inline-svg
													src="media/icons/duotune/general/gen026.svg"
												/>
											</span>
										</a>
									</div>
									<!--end::Name-->
								</div>
								<!--end::User-->
							</div>
						</div>
						<!--end::Title-->
					</div>
					<!--end::Info-->
				</div>
				<!--end::Details-->
			</div>
		</div>
		<!--end::Navbar-->
		
		<div class="col-12 col-lg-2 col-xl-3">
			<ul
				class="
					nav nav-tabs nav-pills nav-justified
					flex-row
					border-0
					flex-md-column
					me-5
					mb-3 mb-md-0
					fs-6
				"
			>
				<li class="nav-item me-0 mb-md-2">
					<a
						class="nav-link btn btn-flex btn-active-info active"
						data-bs-toggle="tab"
						href="#details"
					>
					<i class="bi-gear fs-4 me-3"></i>
					<span class="d-flex flex-column align-items-start">
						<span class="fs-4 fw-bolder text-left">Details</span>
					</span>
					</a>
				</li>
				<li v-if="!isMarketplaceOnly" class="nav-item me-0 mb-md-2">
					<a
						class="nav-link btn btn-flex btn-active-info"
						data-bs-toggle="tab"
						href="#website-presentation"
					>
					<i class="bi-globe fs-4 me-3"></i>
					<span class="d-flex flex-column align-items-start">
						<span class="fs-4 fw-bolder text-left">Neoris website</span>
					</span>
					</a>
				</li>
				<li v-if="!isMarketplaceOnly" class="nav-item me-0 mb-md-2">
					<a
						class="nav-link btn btn-flex btn-active-info"
						data-bs-toggle="tab"
						href="#gmail-link"
					>
					<i class="bi-envelope fs-4 me-3" />
					<span class="d-flex flex-column align-items-start">
						<span class="fs-4 fw-bolder text-left">Link with Gmail</span>
					</span>
					</a>
				</li>
				<li class="nav-item me-0 mb-md-2">
					<a
						class="nav-link btn btn-flex btn-active-info"
						data-bs-toggle="tab"
						href="#payment-methods"
					>
					<i class="bi-credit-card fs-4 me-3" />
					<span class="d-flex flex-column align-items-start">
						<span class="fs-4 fw-bolder text-left">Payment methods</span>
					</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="col-12 col-12 col-lg-10 col-xl-9 tab-content">

			<div class="row tab-pane show active" id="details" role="tabpanel">
				<!--begin::Basic info-->
				<div class="card mb-5 mb-xl-10">
					<!--begin::Card header-->
					<div class="card-header">
						<!--begin::Card title-->
						<div class="card-title m-0">
							<h3 class="fw-bolder m-0">Details</h3>
						</div>
						<!--end::Card title-->
					</div>
					<!--begin::Card header-->

					<!--begin::Content-->
					<div class="collapse show">
						<!--begin::Form-->
						<form class="form" @submit.prevent="saveChanges()" ref="detailsForm">
							<!--begin::Card body-->
							<div class="card-body border-top p-9">
								<!--begin::Input group-->
								<div class="row mb-6">
									<!--begin::Label-->
									<label class="col-lg-4 col-form-label required fw-bold fs-6"
										>Clinic name</label
									>
									<!--end::Label-->

									<!--begin::Col-->
									<div class="col-lg-8">
										<!--begin::Row-->
										<div class="row">
											<!--begin::Col-->
											<div class="fv-row">
												<input
													type="text"
													class="form-control"
													id="name"
													v-model="clinic.name"
													placeholder="Clinic A"
													required
												/>
											</div>
											<!--end::Col-->
										</div>
										<!--end::Row-->
									</div>
									<!--end::Col-->
								</div>
								<!--end::Input group-->

								<!--begin::Input group-->
								<div class="row mb-6">
									<!--begin::Label-->
									<label class="col-lg-4 col-form-label required fw-bold fs-6"
										>Email</label
									>
									<!--end::Label-->

									<!--begin::Col-->
									<div class="col-lg-8 fv-row">
										<input
											type="email"
											class="form-control"
											id="email"
											v-model="clinic.email"
											placeholder="company@example.org"
											required
										/>
									</div>
									<!--end::Col-->
								</div>
								<!--end::Input group-->

								<!--begin::Input group-->
								<div class="row mb-6">
									<!--begin::Label-->
									<label class="col-lg-4 col-form-label fw-bold fs-6">
										<span class="required">Phone number</span>
									</label>
									<!--end::Label-->

									<!--begin::Col-->
									<div class="col-lg-8 fv-row">
										<input
											type="telephone"
											class="form-control"
											id="phone"
											v-model="clinic.phone"
											placeholder="+330601020304"
											required
										/>
									</div>
									<!--end::Col-->
								</div>
								<!--end::Input group-->

								<!--begin::Input group-->
								<div v-if="!isMarketplaceOnly" class="row mb-6">
									<!--begin::Label-->
									<label class="col-lg-4 col-form-label fw-bold fs-6">
										<span class="required">Price of the Neoris operation</span>
									</label>
									<!--end::Label-->

									<!--begin::Col-->
									<div class="col-lg-2 col-4 fv-row">
										<input
											type="number"
											min="0"
											class="form-control"
											id="operation-price"
											v-model="clinic.operationPrice"
											placeholder="2000"
											required
										/>
									</div>
									<!--end::Col-->
									<!--begin::Col-->
									<div class="col-lg-3 col-4 fv-row">
										<select
											class="form-control"
											v-model="clinic.operationCurrency"
											style="appearance: auto"
											required
										>
											<option :value="undefined" disabled>
												Choose currency...
											</option>
											<option>EUR</option>
											<option>USD</option>
											<option>CHF</option>
										</select>
									</div>
									<!--end::Col-->
								</div>
								<!--end::Input group-->
							</div>
							<!--end::Card body-->

							<!--begin::Actions-->
							<div class="card-footer d-flex justify-content-end py-6 px-9">
								<button type="submit" class="btn btn-primary">
									<span class="indicator-label"> Save Changes </span>
									<span class="indicator-progress">
										Please wait...
										<span
											class="spinner-border spinner-border-sm align-middle ms-2"
										></span>
									</span>
								</button>
							</div>
							<!--end::Actions-->
						</form>
						<!--end::Form-->
					</div>
					<!--end::Content-->
				</div>
				<!--end::Basic info-->
				
				<!--begin::Adresses-->
				<div class="card mb-5 mb-xl-10">
					<!--begin::Card header-->
					<div class="card-header">
						<div class="card-title m-0">
							<h3 class="fw-bolder m-0">Addresses</h3>
						</div>
					</div>
					<!--end::Card header-->

					<!--begin::Content-->
					<div class="collapse show">
						<!--begin::Form-->
						<form class="form" @submit.prevent="saveChanges()" ref="addressesForm">
							<!--begin::Card body-->
							<div class="card-body border-top p-9">
								<div
									v-for="(address, idx) in clinic.addresses"
									:key="address.id"
									class="mb-16"
								>
									<h4 class="mb-6">
										<span v-if="address.label == 'DEFAULT'">
											Default clinic address
										</span>
										<span v-else-if="address.label == 'BILLING'">
											Billing address
										</span>
										<span v-else>
											Additional clinic address #{{ idx - 1 }}
											<a
												href="#"
												@click.prevent="removeAddress(idx)"
											>
												<i class="bi bi-trash mr-2 text-danger"></i>
											</a>
										</span>
									</h4>

									<div v-if="address.label == 'BILLING'" class="row mb-6">
										<div class="col-lg-8 fv-row">
											<label class="form-check form-check-inline form-check-solid">
												<input
													v-model="useDefaultAddressForBilling"
													class="form-check-input"
													name="communication[]"
													type="checkbox"
												/>
												<span class="fw-bold ps-2 fs-6">Use default address</span>
											</label>
										</div>
									</div>
									<template v-if="address.label != 'BILLING' || !useDefaultAddressForBilling">
										<div class="row mb-6">
											<label class="col-lg-4 col-form-label required fw-bold fs-6">
												Address line 1
											</label>

											<div class="col-lg-8 fv-row">
												<input
													type="text"
													class="form-control"
													v-model="address.street"
													placeholder="10 Penthievre Street"
													required
												/>
											</div>
										</div>

										<div class="row mb-6">
											<label class="col-lg-4 col-form-label fw-bold fs-6">
												Address line 2
											</label>

											<div class="col-lg-8 fv-row">
												<input
													type="text"
													class="form-control"
													v-model="address.street2"
													placeholder=""
												/>
											</div>
										</div>

										<div class="row mb-6">
											<label class="col-lg-4 col-form-label required fw-bold fs-6"
												>ZIP code / City</label
											>

											<div class="col-lg-4 fv-row">
												<input
													type="text"
													class="form-control"
													v-model="address.zip"
													placeholder="75000"
													required
												/>
											</div>
											<div class="col-lg-4 fv-row">
												<input
													type="text"
													class="form-control"
													v-model="address.city"
													placeholder="Paris"
													required
												/>
											</div>
										</div>

										<div class="row mb-6">
											<label class="col-lg-4 col-form-label fw-bold fs-6">
												State
											</label>

											<div class="col-lg-8 fv-row">
												<input
													type="text"
													class="form-control"
													v-model="address.state"
													placeholder="Ile de France"
												/>
											</div>
										</div>

										<div class="row mb-6">
											<label class="col-lg-4 col-form-label required fw-bold fs-6">
												Country
											</label>

											<div class="col-lg-8 fv-row">
												<v-select
													v-model="address.country"
													:options="countriesList"
													:reduce="(e) => e.name"
													label="name"
													required
												>
													<template #no-options>Search language...</template>
													<template #option="option">
														<div class="d-center">{{ option.name }}</div>
													</template>
													<template #selected-option="option">
														<div class="selected d-center">{{ option.name }}</div>
													</template>
												</v-select>
											</div>
										</div>
									</template>
								</div>

								<button
									class="btn btn-secondary"
									@click.prevent="addAddress()"
								>
									Add an address
								</button>
							</div>

							<div class="card-footer d-flex justify-content-end py-6 px-9">
								<button type="submit" class="btn btn-primary">
									<span class="indicator-label"> Save Changes </span>
									<span class="indicator-progress">
										Please wait...
										<span
											class="spinner-border spinner-border-sm align-middle ms-2"
										></span>
									</span>
								</button>
							</div>
						</form>
						<!--end::Form-->
					</div>
					<!--end::Content-->
				</div>
				<!--end::Adresses-->
			</div>

        	<div class="row tab-pane fade" id="website-presentation" role="tabpanel"><!--begin::Content-->
				<!--begin::Basic info-->
				<div class="card mb-5 mb-xl-10">
					<!--begin::Card header-->
					<div class="card-header">
						<!--begin::Card title-->
						<div class="card-title m-0">
							<h3 class="fw-bolder m-0">Neoris website</h3>
						</div>
						<!--end::Card title-->
					</div>
					<!--begin::Card header-->
					<div class="collapse show">
						<!--begin::Form-->
						<form class="form" @submit.prevent="saveChanges()" ref="detailsForm">
							<!--begin::Card body-->
							<div class="card-body border-top p-9">
								<!--begin::Input group-->
								<div class="row mb-6">
									<!--begin::Label-->
									<label class="col-lg-4 col-form-label fs-6 fw-bold"
										>Display clinic on website</label
									>
									<!--begin::Label-->

									<!--begin::Label-->
									<div class="col-lg-8 d-flex align-items-center">
										<div class="form-check form-check-solid form-switch fv-row">
											<input
												class="form-check-input w-45px h-30px"
												type="checkbox"
												id="display-website"
												v-model="clinic.appearOnSite"
											/>
											<label
												class="form-check-label text-muted mt-1 ms-3"
												for="display-website"
												>Allows Neoris to display your clinic on their website and
												send you prospects.</label
											>
										</div>
									</div>
									<!--begin::Label-->
								</div>
								<!--end::Input group-->

								<!--begin::Input group-->
								<div class="row mb-6" v-if="clinic.appearOnSite">
									<!--begin::Label-->
									<label class="col-lg-4 col-form-label fs-6 fw-bold">
										Receive callback questions
									</label>
									<!--begin::Label-->

									<!--begin::Label-->
									<div class="col-lg-8 d-flex align-items-center">
										<div class="form-check form-check-solid form-switch fv-row">
											<input
												class="form-check-input w-45px h-30px"
												type="checkbox"
												id="display-website"
												v-model="clinic.allowCallbackQuestion"
											/>
											<label
												class="form-check-label text-muted mt-1 ms-3"
												for="display-website"
											>
												Allows you to receive prospects who want to be called back
												from Neoris website.
											</label>
										</div>
									</div>
									<!--begin::Label-->
								</div>
								<!--end::Input group-->
							</div>
							<!--end::Card body-->

							<!--begin::Actions-->
							<div class="card-footer d-flex justify-content-end py-6 px-9">
								<button type="submit" class="btn btn-primary">
									<span class="indicator-label"> Save Changes </span>
									<span class="indicator-progress">
										Please wait...
										<span
											class="spinner-border spinner-border-sm align-middle ms-2"
										></span>
									</span>
								</button>
							</div>
							<!--end::Actions-->
						</form>
						<!--end::Form-->
					</div>
					<!--end::Content-->
				</div>
				<!--end::Basic info-->

				<!--begin::Submit pictures for website-->
				<div v-if="clinic.appearOnSite" class="card mb-5 mb-xl-10">
					<!--begin::Card header-->
					<div class="card-header">
						<!--begin::Card title-->
						<div class="card-title m-0">
							<h3 class="fw-bolder m-0">Pictures submission for Neoris Website</h3>
						</div>
						<!--end::Card title-->
					</div>
					<!--begin::Card header-->

					<!--begin::Content-->
					<div class="collapse show">
						<!--begin::Form-->
						<form class="form" enctype="multipart/form-data" @submit.prevent="saveChanges()" ref="detailsForm">
							<!--begin::Card body-->
							<div class="card-body border-top p-9">
								<div class="row mb-6">
									<label class="col-lg-4 col-form-label fs-6 fw-bold">
										Submit "before/after" pictures that you
										would like to appear on Neoris website.<br>
										<span class="text-muted">
											Pictures are subject to approval by Neoris Team.
										</span>
									</label>
									<div class="col-lg-8 my-auto">
										<div class="row">
											<div class="col-10">
												<input
													:disabled="isUploading"
													ref="beforeAfterFileUpload"
													@change="handleBeforeAfterPictureUpload($event.target.files)"
													type="file"
													class="form-control"
													accept="image/jpeg,image/png"
													multiple
												/>
											</div>
											<div class="col-2">
												<button
													@click.prevent="submitBeforeAfterPicture"
													class="btn btn-primary"
												>
													Submit
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!--end::Card body-->
						</form>
						<!--end::Form-->
					</div>
					<!--end::Content-->
				</div>
				<!--end::Submit pictures for website-->

				<!--begin::Presentation for website-->
				<div v-if="clinic.appearOnSite && !isMarketplaceOnly" class="card mb-5 mb-xl-10">
					<!--begin::Card header-->
					<div class="card-header">
						<!--begin::Card title-->
						<div class="card-title m-0">
							<h3 class="fw-bolder m-0">Presentation for Neoris Website</h3>
						</div>
						<!--end::Card title-->
					</div>
					<!--begin::Card header-->

					<!--begin::Content-->
					<div class="collapse show">
						<!--begin::Form-->
						<form class="form" enctype="multipart/form-data" @submit.prevent="saveChanges()" ref="detailsForm">
							<!--begin::Card body-->
							<div class="card-body border-top p-9">
								<div class="row mb-6">
									<label class="col-lg-4 col-form-label fs-6 fw-bold">
										Picture<br />
										<span class="text-muted">
											A picture that represents the clinic
										</span>
									</label>
									<div class="col-lg-8">
										<div class="row">
											<div class="col-10">
												<input
													:disabled="isUploading"
													@change="handleClinicImageUpload($event.target.files[0])"
													type="file"
													class="form-control"
													accept="image/jpeg,image/png"
												/>
											</div>
											<div class="col-2">
												<img class="img-fluid" :src="`${apiUrl}/clinics-images/${clinic.presentationDraft.image}`">
											</div>
										</div>
									</div>
								</div>

								<div class="row mb-6">
									<label class="col-lg-4 col-form-label required fw-bold fs-6">
										Clinic description<br />
										<span class="text-muted"> Max. 400 characters </span>
									</label>
									<div class="col-lg-8">
										<div class="row">
											<div class="fv-row">
												<textarea
													class="form-control"
													v-model="clinic.presentationDraft.description"
													maxlength="400"
													required
												></textarea>
											</div>
										</div>
									</div>
								</div>

								<div class="row mb-6">
									<label class="col-lg-4 col-form-label fw-bold fs-6">
										<span class="required">Spoken languages</span>
									</label>
									<div class="col-lg-8 fv-row">
										<v-select
											v-model="clinic.presentationDraft.spokenLanguages"
											:options="iso6391languages"
											:reduce="(e) => e.code"
											label="name"
											multiple
										>
											<template #no-options>Search language...</template>
											<template #option="option">
												<div class="d-center">{{ option.name }}</div>
											</template>
											<template #selected-option="option">
												<div class="selected d-center">{{ option.name }}</div>
											</template>
										</v-select>
									</div>
								</div>
							</div>
							<!--end::Card body-->

							<!--begin::Actions-->
							<div class="card-footer d-flex py-6 px-9" style="justify-content: space-between;">
								<div v-if="!clinic.presentationDraft.approved" class="alert alert-warning mb-0" role="alert">
									⚠️ Modifications to your presentation have been submitted for 
									review to the Neoris team before appearing on Neoris website.
								</div>
								<div v-else class="alert alert-success mb-0" role="alert">
									This is how your presentation appears on Neoris website.
									Modifications will be subject to review by Neoris team.
								</div>
								<button type="submit" class="btn btn-primary">
									<span class="indicator-label"> Save Changes </span>
									<span class="indicator-progress">
										Please wait...
										<span
											class="spinner-border spinner-border-sm align-middle ms-2"
										></span>
									</span>
								</button>
							</div>
							<!--end::Actions-->
						</form>
						<!--end::Form-->
					</div>
					<!--end::Content-->
				</div>
				<!--end::Presentation for website-->
			</div>

        	<div v-if="!isMarketplaceOnly" class="row tab-pane fade" id="gmail-link" role="tabpanel">
				<!--begin::Link with Gmail-->
				<div class="card mb-5 mb-xl-10">
					<!--begin::Card header-->
					<div class="card-header">
						<!--begin::Card title-->
						<div class="card-title m-0">
							<h3 class="fw-bolder m-0">Link with Gmail</h3>
						</div>
						<!--end::Card title-->
					</div>
					<!--begin::Card header-->

					<!--begin::Content-->
					<div class="collapse show">
						<!--begin::Card body-->
						<div class="card-body border-top p-9">
							<div v-if="isGmailLinked">
								<i class="bi bi-check-circle text-success" /> Your Gmail mailbox is
								linked
							</div>

							<div v-else>
								<h3>How to link your Gmail mailbox to your Neoris Dashboard?</h3>
								<ol>
									<li>
										Click on the "Connect with Gmail" below
										<button
											@click="connectWithGmail"
											class="btn btn-primary d-block mb-2"
										>
											Connect with Gmail
										</button>
									</li>
									<li>
										Login with the Gmail account you want to link to your Neoris
										Dashboard
									</li>
									<li>If prompted, click on "Continue"</li>
									<li>Tick all required permissions and "Allow" or "Continue"</li>
									<li>
										Copy your authorization code (you can now close the popup
										window)
									</li>
									<li>Paste it in the field below and click on "Finalize"</li>
								</ol>

								<div class="mt-4 row">
									<div class="col-8 col-md-4">
										<input
											type="text"
											class="form-control"
											v-model="clinic.authorizationCode"
											placeholder="Gmail Authorization Code"
										/>
									</div>
									<button
										@click="finalizeGmail"
										class="col-1 col-sm-2 btn btn-primary"
									>
										Finalize
									</button>
								</div>
							</div>
						</div>
						<!--end::Card body-->
					</div>
					<!--end::Content-->
				</div>
				<!--end::Link with Gmail-->
			</div>

			<div class="row tab-pane fade" id="payment-methods" role="tabpanel">
				<!--begin::Credit Card-->
				<div id="payment" class="card mb-5 mb-xl-10">
					<!--begin::Card header-->
					<div class="card-header">
						<!--begin::Card title-->
						<div class="card-title m-0">
							<h3 class="fw-bolder m-0">Payment method</h3>
						</div>
						<!--end::Card title-->
					</div>
					<!--begin::Card header-->

					<!--begin::Content-->
					<div class="collapse show">
						<!--begin::Card body-->
						<div class="card-body border-top p-9">
							<h5 class="mb-4">Credit card</h5>

							<button
								type="submit"
								class="btn btn-primary float-right mb-8"
								@click="editCreditCard"
								:disabled="editPaymentMethodLoading"
							>
								<span :class="{ 'indicator-label': !editPaymentMethodLoading }">
									Edit credit card
								</span>
							</button>

							<div v-if="paymentMethods && paymentMethods.length">
								<div
									v-for="({ createdAt, details }, idx) of paymentMethods.filter(
										({ method }) => method == 'creditcard'
									)"
									:key="idx"
								>
									<i
										class="bi bi-check-circle text-success d-inline-block mt-4 mx-2 align-top"
									/>
									<p class="d-inline-block">
										<strong v-if="details.cardLabel">
											{{ details.cardLabel.toUpperCase() }}
											- **** {{ details.cardNumber }} - Expires
											{{ new Date(details.cardExpiryDate).toLocaleDateString() }}
										</strong>
										<br /><small
											>Added {{ new Date(createdAt).toLocaleDateString() }}</small
										>
									</p>
								</div>
							</div>

							<hr style="clear: both" />

							<h5 class="mt-4 mb-4">SEPA Direct Debit</h5>

							<button
								v-if="!showSepaMandateForm"
								class="btn btn-primary float-right mb-8"
								@click="showSepaMandateForm = true"
							>
								Edit SEPA Mandate
							</button>

							<div v-if="paymentMethods && paymentMethods.length">
								<div
									v-for="({ createdAt, details }, idx) of paymentMethods.filter(
										({ method }) => method == 'directdebit'
									)"
									:key="idx"
								>
									<i
										class="bi bi-check-circle text-success d-inline-block mt-4 mx-2 align-top"
									/>
									<p class="d-inline-block">
										<strong v-if="details.consumerAccount">
											IBAN {{ details.consumerAccount }} - BIC
											{{ details.consumerBic }}
										</strong>
										<br /><small
											>Added {{ new Date(createdAt).toLocaleDateString() }}</small
										>
									</p>
								</div>
							</div>

							<div v-if="showSepaMandateForm">
								<form
									class="form"
									@submit.prevent="saveSepaMandate()"
									ref="addressesForm"
								>
									<div class="card-body p-9">
										<div class="row mb-6">
											<label class="col-lg-2 col-form-label required fw-bold fs-6"
												>IBAN</label
											>
											<div class="col-lg-5">
												<div class="row">
													<div class="fv-row">
														<input
															type="text"
															class="form-control"
															v-model="sepaMandate.consumerAccount"
															placeholder="FR1112739000303524277265N55"
															required
														/>
													</div>
												</div>
											</div>
										</div>

										<div class="row mb-6">
											<label class="col-lg-2 col-form-label required fw-bold fs-6"
												>BIC</label
											>
											<div class="col-lg-5 fv-row">
												<input
													type="text"
													class="form-control"
													v-model="sepaMandate.consumerBic"
													placeholder="CMCIFRPP"
													required
												/>
											</div>
										</div>

										<p class="text-helper">
											⚠️ Please ensure that you have signed a SEPA Direct Debit
											mandate with Neoris prior to submitting this form.
										</p>
										<button
											type="submit"
											class="btn btn-primary"
											:disabled="editPaymentMethodLoading"
										>
											<span
												v-if="!editPaymentMethodLoading"
												class="indicator-label"
											>
												Save SEPA Mandate
											</span>
											<span v-else>
												Please wait...
												<span
													class="spinner-border spinner-border-sm align-middle ms-2"
												></span>
											</span>
										</button>
									</div>
								</form>
							</div>
						</div>
						<!--end::Card body-->
					</div>
					<!--end::Content-->
				</div>
				<!--end::Credit Card-->
			</div>
		</div>
	</div>
</template>

<script>
import { Field, Form } from "vee-validate";

import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useToast } from "vue-toastification";
import "vue-select/dist/vue-select.css";

import Iso6391languages from "@/core/constants/Iso-639-1-to-language";
import CountriesList from "@/core/constants/Countries";

const clinicId = JwtService.getUser().clinic.id;

export default {
	component: {
		Field,
		Form,
	},
	setup() {
		const toast = useToast();
		const isMarketplaceOnly = JwtService.isMarketplaceOnly();
		console.log("isMarketplaceOnly -> ", isMarketplaceOnly);
		return {
			toast,
			isMarketplaceOnly,
		};
	},
	data() {
		return {
			clinic: null,
			isUploading: false,
			image: null,
			beforeAfterPictures: [],
			isGmailLinked: false,
			paymentMethods: null,
			editPaymentMethodLoading: false,
			showSepaMandateForm: false,
			sepaMandate: {
				consumerAccount: "",
				consumerBic: "",
			},
			iso6391languages: Iso6391languages,
			countriesList: CountriesList,
      		apiUrl: process.env.VUE_APP_API_URL,
			useDefaultAddressForBilling: true,
		};
	},
	created() {
		this.fetchClinic();
	},
	mounted() {
		if (this.$route.query.payment) {
			this.toast.success(
				"Payment method added, please refresh this page in a few minutes if it is not already listed"
			);
		}
	},
	computed: {
	},
	methods: {
		popupCenter({ url, title, w, h }) {
			// Fixes dual-screen position                             Most browsers      Firefox
			const dualScreenLeft =
				window.screenLeft !== undefined ? window.screenLeft : window.screenX;
			const dualScreenTop =
				window.screenTop !== undefined ? window.screenTop : window.screenY;

			const width = window.innerWidth
				? window.innerWidth
				: document.documentElement.clientWidth
				? document.documentElement.clientWidth
				: screen.width;
			const height = window.innerHeight
				? window.innerHeight
				: document.documentElement.clientHeight
				? document.documentElement.clientHeight
				: screen.height;

			const systemZoom = width / window.screen.availWidth;
			const left = (width - w) / 2 / systemZoom + dualScreenLeft;
			const top = (height - h) / 2 / systemZoom + dualScreenTop;
			const newWindow = window.open(
				url,
				title,
				`
        directories=0,
        titlebar=0,
        toolbar=0,
        location=0,
        status=0,
        menubar=0,
        scrollbars=yes,
        width=${w / systemZoom}, 
        height=${h / systemZoom}, 
        top=${top}, 
        left=${left}
        `
			);

			if (window.focus) newWindow.focus();
		},
		connectWithGmail() {
			ApiService.get("gmail/authorization").then(({ data }) => {
				this.popupCenter({
					url: data.authUrl,
					title: "Signin with Google",
					w: 400,
					h: 600,
				});
			});
		},
		finalizeGmail() {
			ApiService.post(
				"gmail/authorization",
				{ token: this.clinic.authorizationCode },
				{ responseToast: { text: "Gmail mailbox linked" } }
			).then(() => {
				this.fetchClinic();
			});
		},
		fetchClinic() {
			ApiService.get("clinics", clinicId).then(({ data }) => {
				// Add billing address if not present (copy of default address)
				if (!data.addresses.find(e => e.label == "BILLING")) {
					const billingAddr = {
						...data.addresses.find(e => e.label == "DEFAULT"),
						label: "BILLING",
					};
					delete billingAddr._id;
					data.addresses.push(billingAddr);
				}

				// Check if billing address == default one
				const billingAddr = data.addresses.find(e => e.label == "BILLING");
				const defaultAddr = data.addresses.find(e => e.label == "DEFAULT");
				this.useDefaultAddressForBilling = (
					billingAddr.street == defaultAddr.street
					&& billingAddr.street2 == defaultAddr.street2
					&& billingAddr.zip == defaultAddr.zip
					&& billingAddr.city == defaultAddr.city
					&& billingAddr.state == defaultAddr.state
					&& billingAddr.country == defaultAddr.country
				);

				this.clinic = data;
			});
			this.fetchClinicPaymentMethods();
			
			if (!this.isMarketplaceOnly) {
				ApiService.get("gmail/labels/INBOX").then(() => {
					this.isGmailLinked = true;
				});
			}
		},
		fetchClinicPaymentMethods() {
			ApiService.query(`clinics/${clinicId}/payment-methods`).then(
				({ data }) => {
					this.paymentMethods = data.filter(({ status }) => status == "valid");
				}
			);
		},
		editCreditCard() {
			this.editPaymentMethodLoading = true;
			ApiService.post(
				`clinics/${clinicId}/payment-methods/credit-card`,
				{}
			).then(({ data }) => {
				window.location.href = data._links.checkout.href;
			});
		},
		saveSepaMandate() {
			this.editPaymentMethodLoading = true;
			ApiService.post(
				`clinics/${clinicId}/payment-methods/sepa-direct-debit`,
				this.sepaMandate
			)
				.then(({ data }) => {
					console.log(data);
					this.toast.success(
						"Payment method added, please refresh this page in a few minutes if it is not already listed"
					);
					this.editPaymentMethodLoading = false;
					this.showSepaMandateForm = false;
					this.fetchClinicPaymentMethods();
				})
				.catch(() => {
					this.editPaymentMethodLoading = false;
				});
		},
		handleClinicImageUpload(file) {
			this.isUploading = true;
			const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
			if (!allowedTypes.includes(file.type)) {
				alert("Only jpeg and png images are allowed");
				this.isUploading = false;
				return;
			}
			if (file.size > 2 * 1000000) { // Max. 2Mo
				alert("File too large, max. 2Mo");
				this.isUploading = false;
				return;
			}
			this.image = file;
			this.isUploading = false;
		},
		handleBeforeAfterPictureUpload(files) {
			this.isUploading = true;
			this.beforeAfterPictures = [];
			for (const file of files) {
				const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
				if (!allowedTypes.includes(file.type)) {
					alert("Only jpeg and png images are allowed");
					this.isUploading = false;
					continue;
				}
				if (file.size > 5 * 1000000) { // Max. 5Mo
					alert("File too large, max. 5Mo");
					this.isUploading = false;
					continue;
				}
				console.log("pushing file --> ", file);
				this.beforeAfterPictures.push(file);
			}
			this.isUploading = false;
		},
		submitBeforeAfterPicture() {
			if (!this.beforeAfterPictures || !this.beforeAfterPictures.length) {
				alert('Please choose a file to submit.');
				return;
			}

			Promise.all(
				this.beforeAfterPictures.map(picture => {
					console.log("submitting picture -> ", picture);
					const formData = new FormData();
					formData.append("image", picture);
					return ApiService.post("clinics-pictures", formData);
				})
			).then(() => {
				this.beforeAfterPicture = [];
				console.log("RESET PICTURES => ", this.beforeAfterPictures);
				this.$refs.beforeAfterFileUpload.value = null;
				this.toast.success("Before/after pictures submitted to Neoris Team.");
			});
		},
		addAddress() {
			this.clinic.addresses.push({ label: "CLINIC" });
		},
		removeAddress(idx) {
			if (idx < 0 && idx >= this.clinic.addresses.length) return;
			const addr = this.clinic.addresses[idx];
			if (addr.label != "CLINIC") return; // Prevent removing DEFAULT or BILLING address
			this.clinic.addresses.splice(idx, 1);
		},
		//executed when addresses form is submitted
		saveChanges() {
			if (this.clinic.operationPrice && !this.clinic.operationCurrency) {
				this.toast.error("Please select the operation's price currency");
				return;
			}

			// Handle billing address
			if (this.useDefaultAddressForBilling) {
				const defaultAddr = this.clinic.addresses.find(e => e.label == "DEFAULT");
				const billingAddr = this.clinic.addresses.find(e => e.label == "BILLING");
				
				billingAddr.street = defaultAddr.street;
				billingAddr.street2 = defaultAddr.street2;
				billingAddr.zip = defaultAddr.zip;
				billingAddr.city = defaultAddr.city;
				billingAddr.state = defaultAddr.state;
				billingAddr.country = defaultAddr.country;
			}

			const config = {
				responseToast: {
					text: "Settings updated successfully.",
				},
			};
			ApiService
				.update("clinics", this.clinic.id, this.clinic, config)
				.then(() => {
					if (this.image) {
						const config = {
							responseToast: {
								text: "Clinic image updated successfully.",
							},
						};
						const formData = new FormData();
						formData.append("image", this.image);
						ApiService
							.update("clinics", this.clinic.id, formData, config)
							.then(() => { this.image = null });
					}
				});
		},
	},
};
</script>

<style lang="scss" scoped>
	.text-left {
		text-align: left;
	}
</style>
